import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, switchMap, throwError } from "rxjs";
import { AuthUtils } from "app/core/auth/auth.utils";
import { UserService } from "app/core/user/user.service";
import { environment } from "environments/environment";
import { User } from "../user/user.types";

const END_POINT = `${environment.serviceURL}`;

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _userService: UserService) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: any) {
        localStorage.setItem("accessToken", AuthUtils.generateToken(token));
    }

    get accessToken(): string {
        return localStorage.getItem("accessToken") ?? "";
    }

    /**
     * Setter & getter for user token
     */
     set userToken(user: any) {
        localStorage.setItem("userToken", AuthUtils.generateToken(user));
    }

    get userToken(): string {
        return localStorage.getItem("userToken") ?? "";
    }    

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        const deviceId = localStorage.getItem("deviceId");
        const json = {
            deviceId: deviceId,
            username: email
        };
        return this._httpClient.post(`${END_POINT}/v1/forgot-password`, json);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(credentials: {current: any; password: any; deviceId: String;}): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/reset-password`, credentials).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    resetPasswordToken(credentials: {token: any; password: any; deviceId: String;}): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/reset-password-token`, credentials).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    device(): Observable<any> {
        const deviceId = localStorage.getItem("deviceId");
        if (deviceId == null) {
            const token = "_" + Math.random().toString(36).substr(2, 9);
            const device = {
                token: token,
                platformId: 2,
                notificationStatusId: 1,
                statusId: 1,
            };
            return this._httpClient.post(`${END_POINT}/v1/device`, device).pipe(
                switchMap((response: any) => {
                    if (response.isError) {
                        this._authenticated = false;
                        this._userService.user = null;
                        this.accessToken = null;
                        this.userToken = null;
                        return of(null);
                    } else {
                        localStorage.setItem("userDeviceId", response.data.id);
                        localStorage.setItem("deviceId", response.data.id);
                        return of(response.data.id);
                    }
                })
            );
        } else {
            return of(deviceId);
        }
    }

    signIn(credentials: {username: string; password: string; deviceId: String;}): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError("User is already logged in.");
        }
        return this._httpClient.post(`${END_POINT}/v1/signin`, credentials).pipe(
            switchMap((response: any) => {
                if (response.isError) {
                    this._authenticated = false;
                    this._userService.user = null;
                } else {
                    this._authenticated = true;
                    this.accessToken = response.token;
                    this.userToken = response.data;
                    this._userService.user = response.data;
                    localStorage.setItem("isMainUser", "1");
                    localStorage.setItem("auth", "1");
                    
                }
                return of(response);
            })
        );
    }

    signInSocial(data: {email: string; externalId: string; authToken: string; name: String; avatar: String; downloadAvatar?: String; registerTypeId: String; profileId: String; deviceId?: String;}): Observable<any> {

        const deviceId = localStorage.getItem("deviceId");
        data.downloadAvatar = "1";
        data.deviceId = deviceId;

        return this._httpClient.post(`${END_POINT}/v1/user`, data).pipe(
            switchMap((response: any) => {
                if (response.isError) {
                    this._authenticated = false;
                    this._userService.user = null;
                } else {
                    this._authenticated = true;
                    this.accessToken = response.token;
                    this.userToken = response.data;
                    this._userService.user = response.data;
                    localStorage.setItem("auth", "1");
                }
                return of(response);
            })
        );
    }    

    /**
     * Sign in using the access token
     */
     signInUsingToken(): Observable<any>{
        const user: User = AuthUtils.getUserByToken(localStorage.getItem('userToken') ?? '');
        const token = AuthUtils.getUserByToken(localStorage.getItem('accessToken') ?? '');
        this._authenticated = true;
        this.accessToken = token;
        this.userToken = user;
        // Store the user on the user service
        this._userService.user = user;
        return of(true);
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>{
        const json = {
            deviceId: localStorage.getItem('deviceId')
        };

        return this._httpClient.post(`${END_POINT}/v1/signout`, json).pipe(
            switchMap((response: any) => {
                /*localStorage.removeItem('userToken');
                localStorage.removeItem('deviceId');
                localStorage.removeItem('accessToken');*/
                localStorage.clear();
                this._authenticated = false;
                return of(true);
            })
        );


        
    }    

    /**
     * Sign up
     *
     * @param user
     */
    signUp(data: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/user`, data).pipe(
            switchMap((response: any) => {
                if (response.isError) {
                    this._authenticated = false;
                    this._userService.user = null;
                } 
                return of(response);
            })
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string; }): Observable<any> {
        return this._httpClient.post("api/auth/unlock-session", credentials);
    }

    /**
     * Check the authentication status
     */
     check(): Observable<boolean>{
         // Check if the user is logged in
         if ( this._authenticated ){
             return of(true);
         }
 
         // Check the access token availability
         if ( !this.accessToken ){
             return of(false);
         }
 
         // Check the access token expire date
         if ( AuthUtils.isTokenExpired(this.accessToken) ){
             return of(false);
         }
 
         // return of(true);
         // If the access token exists and it didn't expire, sign in using it
         return this.signInUsingToken();
     }


    saveFireToken(json): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/token-device`, json).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }
}
