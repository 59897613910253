import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { FeedMenuComponent } from './menu.component';
import { FeedMenuRoutingModule } from './menu.routing';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
    declarations: [ FeedMenuComponent ]
    ,imports: [ FeedMenuRoutingModule, TranslocoModule, CommonModule ]
    ,exports: [ FeedMenuComponent ]
})
export class FeedMenuModule
{
}
