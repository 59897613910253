import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { UserService } from 'app/core/user/user.service';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationTypeEnum, ObserverUpdateData, StatusEnum } from 'app/core/types/enums.type';
import { UploadAWSService } from 'app/core/services/upload.service';
import { PushNotificationService } from 'app/core/services/push-notification.service';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    @Input() unreadCount: number = 0;

    public statusEnum = StatusEnum;
    public notificationType = NotificationTypeEnum;

    notifications = [];

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _overlay: Overlay,
        private _uploadService: UploadAWSService,
        private _userService: UserService,
        private _transloco: TranslocoService,
        private _viewContainerRef: ViewContainerRef,
        private _notificationService: PushNotificationService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._userService.updateProfile$.subscribe((message) => {
            this.reloadPage(message)
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._notificationService.setPushNotification(0);
        this._changeDetectorRef.markForCheck();
        this.findNotifications();
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        // this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void {

        const json = {
            "notificationId": notification.id,
            "deviceId": localStorage.getItem('deviceId'),
            "statusId": this.statusEnum.active
        }

        debugger;
        console.log(json);

        this._userService.updateNotification(json).pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (response) => {
                if (response.isError) {
                    const msg = this._transloco.translateObject('common.generic_error');
                } else {
                    this.notifications = response.data;
                    //this._notificationService.setPushNotification(0);
                    this._changeDetectorRef.markForCheck();
                }
            }, error: (e) => {
                const msg = this._transloco.translateObject('feed.server_errors');
            }
        });
    }

    declineTeamBusiness(item: any, idx: any): void {
        this._userService.removeTeamBusiness({ deviceId: localStorage.getItem('deviceId'), id: item.detail.id }).subscribe(
            (response) => {
                if (response.isError) {
                    const msg = this._transloco.translateObject('common.generic_error');
                } else {
                    this.notifications[idx].readStatusId = this.statusEnum.active;
                    this.notifications[idx].detail.statusId = this.statusEnum.delete;
                    this._changeDetectorRef.markForCheck();
                }
            }, (_err) => {
                const msg = this._transloco.translateObject('feed.server_errors');
            }
        );
    }

    acceptTeamBusiness(item: any, idx: any): void {
        this._userService.acceptTeamBusiness({ deviceId: localStorage.getItem('deviceId'), id: item.detail.id }).subscribe(
            (response) => {
                if (response.isError) {
                    const msg = this._transloco.translateObject('common.generic_error');
                } else {
                    this.notifications[idx].readStatusId = this.statusEnum.active;
                    this.notifications[idx].detail.statusId = this.statusEnum.active;
                    this._changeDetectorRef.markForCheck();
                    this._userService.sendUpdateProfile(ObserverUpdateData.collaborators);
                }
            }, (_err) => {
                const msg = this._transloco.translateObject('feed.server_errors');
            }
        );
    }

    findNotifications() {
        this.notifications = [];
        this._userService.findNotification({ deviceId: localStorage.getItem('deviceId') }).subscribe({
            next: (response) => {
                if (response.isError) {
                    this.notifications = [];
                } else {
                    this.notifications = response.data;
                    this._changeDetectorRef.markForCheck();
                }
            }, error: (_e) => {
                this.notifications = [];
            }
        });
    }

    updateNotification(json) {
        this._userService.updateNotification(json).subscribe({
            next: (response) => {
                if (response.isError) {
                    this.notifications = [];
                } else {
                    this.notifications = response.data;
                    this._changeDetectorRef.markForCheck();
                }
            }, error: (_e) => {
                this.notifications = [];
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    downloadFile(keyFileName) {
        if (keyFileName != null && keyFileName != '') {
            const url = this._uploadService.urlFile(keyFileName);
            return url;
        }
        return null;
    }

    reloadPage(option: ObserverUpdateData) {
        switch (option) {
            case ObserverUpdateData.profile:
                this.findNotifications();
                break;
        }
    }
}
