<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-600 text-red-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'" class="surthex-green"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded overflow-hidden shadow-lg" *transloco="let t">

        <!-- Header -->
        <div class="flex flex-shrink-0 items-center py-2 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()" matTooltip="Notificaciones" aria-label="Mantente actualizado con perfil en Surthex">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">{{ 'side_menu.notifications' | transloco }}</div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <ng-container *ngFor="let notification of notifications; let i = index;">

                <div class="flex group hover:bg-gray-50" [ngClass]="{'surthex-bg-unread': notification.readStatusId == statusEnum.pending}">
                    <div class="flex w-full" (click)="toggleRead(notification)">
                        <div class="flex flex-auto py-5 px-6">
                            <ng-container *ngIf="notification.detail?.avatar">
                                <img class="flex-shrink-0 w-8 h-8 mr-4 border-2 rounded-full overflow-hidden rounded-full object-cover object-center" [src]="downloadFile(notification.detail?.avatar)">
                            </ng-container>
                            <div class="flex flex-col flex-auto">
                                <ng-container *ngIf="notification.notificationTypeId && notification.notificationTypeId !=11 ">
                                    <container-element [ngSwitch]="notification.notificationTypeId"> 
                                        <some-element *ngSwitchCase="notificationType.requestTeam">
                                            <div class="font-normal line-clamp-2 text-left"><strong>{{notification.detail?.name}}</strong>, te invita a ser parte de su equipo</div>
                                            <ng-container *ngIf="notification.detail?.statusId == statusEnum.pending">
                                                <div class="space-x-2 my-2">
                                                    <button class="itz-btn-request" mat-flat-button type="button" [color]="'primary'" (click)="acceptTeamBusiness(notification, i)">{{'common.confirm' | transloco}}</button>
                                                    <button class="itz-btn-request" mat-stroked-button type="button" (click)="declineTeamBusiness(notification, i)">{{'common.reject' | transloco}}</button>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="notification.detail?.statusId == statusEnum.active">
                                                <div class="itz-text-cool-grey text-sm italic my-1">Invitación aceptada</div>
                                            </ng-container>
                                            <ng-container *ngIf="notification.detail?.statusId == statusEnum.delete">
                                                <div class="itz-text-cool-grey text-sm italic my-1">Invitación rechazada</div>
                                            </ng-container>
                                        </some-element>
                                        <some-element *ngSwitchCase="notificationType.requestFollow">
                                            <div class="font-normal line-clamp-2"><strong>{{notification.detail?.name}}</strong>, quiere ser tu amigo.</div>
                                        </some-element>
                                        <some-element *ngSwitchCase="notificationType.publishPost">
                                            <div class="font-normal line-clamp-2"><strong>{{notification.detail?.name}}</strong>, realizó una publicación recientemente.</div>
                                        </some-element>
                                        <some-element *ngSwitchCase="notificationType.publishMoment">
                                            <div class="font-normal line-clamp-2"><strong>{{notification.detail?.name}}</strong>, publicó un Moment recientemente.</div>
                                        </some-element>
                                        <some-element *ngSwitchCase="notificationType.responseFollow">
                                            <div class="font-normal line-clamp-2"><strong>{{notification.detail?.name}}</strong>, aceptó tu solicitud de amistad.</div>
                                        </some-element>
                                        <some-element *ngSwitchCase="notificationType.salesApproved">
                                            <div class="font-normal line-clamp-2"><strong>{{notification.detail?.name}}</strong>, tu registro fue <strong>aprobado</strong> en Surthex.</div>
                                        </some-element>
                                        <some-element *ngSwitchCase="notificationType.salesRejected">
                                            <div class="font-normal line-clamp-2"><strong>{{notification.detail?.name}}</strong>, tu registro fue <strong>rechazado</strong> en Surthex.</div>
                                        </some-element>
                                        <some-element *ngSwitchCase="notificationType.addBadge">
                                            <div class="font-normal line-clamp-2">La insignia <strong>{{notification.detail?.name}}</strong>, te fue <strong>otorgada</strong>.</div>
                                        </some-element>
                                        <some-element *ngSwitchCase="notificationType.removeBadge">
                                            <div class="font-normal line-clamp-2">La insignia <strong>{{notification.detail?.name}}</strong>, te fue <strong>retirada</strong>.</div>
                                        </some-element>
                                    </container-element>
                                </ng-container>
                                <ng-container *ngIf="notification.description">
                                    <div class="line-clamp-2">{{notification.description}}</div>
                                </ng-container>
                                <div class="mt-2 text-sm leading-none text-secondary text-right ">
                                    {{notification.dateCreated | date:'MMM dd, h:mm a'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!notifications || !notifications.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon class="text-primary-500-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">{{ 'side_menu.no_notifications' | transloco }}</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{ 'side_menu.empty_notifications' | transloco }}</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>