import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, Params } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { UploadAWSService } from 'app/core/services/upload.service';
import { ObserverUpdateData } from 'app/core/types/enums.type';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;
    tmpProfile;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _uploadService: UploadAWSService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._userService.updateProfile$.subscribe((message) => {
            this.reloadPage(message)
        });

        this.findUserStatus();        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }
    }

    /**
     * Profile
     */
    profile(): void {
        this._router.navigate([`/feed/${this.user.nickname}`]);
    }

    /**
     * account
     */
     account(): void {
        this._router.navigate(['/settings']);
    }    

    /**
     * Sign out
     */
    signOut(): void {
        //localStorage.removeItem("deviceId");
        //localStorage.clear();
        this._router.navigate(['/sign-out']);
    }

    findUserStatus(){
        this._userService.findUserStatus().subscribe(
          (response) => {
            if (!response.isError) {
              if(response.data.length > 0){
                this.user = response.data[0];
                this._changeDetectorRef.markForCheck();
              }
            }
          },(_err) => {
          }
        ); 
      }

    downloadFile(keyFileName) {
        if(keyFileName != null && keyFileName != ''){
          const url = this._uploadService.urlFile(keyFileName);
          return url;
        }
        return null; 
    } 

    reloadPage(option: ObserverUpdateData){
        switch(option){
            case ObserverUpdateData.profile:
                this.findUserStatus();
            break;
        }
    }
}
