<!-- Item wrapper -->
<div class="fuse-horizontal-navigation-item-wrapper relative">
    <!-- Item with an internal link -->
    <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
        <div class="fuse-horizontal-navigation-item" [ngClass]="{'fuse-horizontal-navigation-item-active-forced': item.active}" [routerLink]="[item.link]" [routerLinkActive]="'fuse-horizontal-navigation-item-active'" [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="item.tooltip">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>
</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Icon -->
    <ng-container *ngIf="selected">
        <img [src]="item.iconSelected">
    </ng-container>
    <ng-container *ngIf="!selected">
        <img [src]="item.icon">
    </ng-container>

    <!-- Badge -->
    <ng-container *ngIf="item.hasBadge == '1' && badge > 0">
        <div class="fuse-horizontal-navigation-item-badge absolute right-2 bottom-0 rounded-full bg-red-600 text-red-50">
            <div class="fuse-horizontal-navigation-item-badge-content w-4 h-4">
                {{badge}}
            </div>
        </div>
    </ng-container>

</ng-template>