import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    {path: '', pathMatch : 'full', redirectTo: 'feed'},

    // Redirect signed in user to the '/dashboards/project'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'feed'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password/:token', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            // Social
            // {path: 'store/:product/:level', loadChildren: () => import('app/modules/social/store/store.module').then(m => m.StoreModule)},
            {path: 'feed', loadChildren: () => import('app/modules/social/feed/feed.module').then(m => m.FeedModule)},
            {path: 'moments', loadChildren: () => import('app/modules/social/moments/moments.module').then(m => m.MomentsModule)},
            {path: 'feed/:nickname', loadChildren: () => import('app/modules/social/feedme/feedme.module').then(m => m.FeedMeModule)},
            {path: 'post/:nickname/:id', loadChildren: () => import('app/modules/social/post-detail/post-detail.module').then(m => m.PostDetailModule)},
            {path: 'store/:level/:friendly', loadChildren: () => import('app/modules/social/store/store.module').then(m => m.StoreModule)},
            {path: 'product/:friendly', loadChildren: () => import('app/modules/social/product/product.module').then(m => m.ProductModule)},
            {path: 'categories', loadChildren: () => import('app/modules/social/categories/categories.module').then(m => m.CategoriesModule)},
            {path: 'categories/:friendly', loadChildren: () => import('app/modules/social/categories/categories.module').then(m => m.CategoriesModule)},
            {path: 'companies', loadChildren: () => import('app/modules/social/companies/companies.module').then(m => m.CompaniesModule)},
            {path: 'badges', loadChildren: () => import('app/modules/social/badges/badges.module').then(m => m.BadgesModule)},
            {path: 'chat', loadChildren: () => import('app/modules/social/chat/chat.module').then(m => m.ChatModule)},
            {path: 'chat/:friendly', loadChildren: () => import('app/modules/social/chat/chat.module').then(m => m.ChatModule)},
            {path: 'contacts', loadChildren: () => import('app/modules/social/following/following.module').then(m => m.FollowingModule)},
            {path: 'cart', loadChildren: () => import('app/modules/social/cart/cart.module').then(m => m.CartModule)},
            {path: 'orders', loadChildren: () => import('app/modules/social/orders/orders.module').then(m => m.OrdersModule)},
            {path: 'settings', loadChildren: () => import('app/modules/social/settings/settings.module').then(m => m.SettingsModule)},
            // Manager
            {path: 'manager', loadChildren: () => import('app/modules/manager/manager.module').then(m => m.ManagerModule)},
            {path: 'ecommerce', loadChildren: () => import('app/modules/admin/apps/ecommerce/ecommerce.module').then(m => m.ECommerceModule)},        

            // Pages
            {path: 'pages', children: [
                // Activities
                {path: 'activities', loadChildren: () => import('app/modules/admin/pages/activities/activities.module').then(m => m.ActivitiesModule)},
                // Coming Soon
                {path: 'coming-soon', loadChildren: () => import('app/modules/admin/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)},
                // Error
                {path: 'error', children: [
                    {path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
                    {path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then(m => m.Error500Module)}
                ]},

                // Maintenance
                {path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule)},

                // Profile
                {path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.module').then(m => m.ProfileModule)},

            ]},

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];

