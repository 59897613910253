import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BoolEnum, ModuleAdminEnum, ObserverUpdateData, UserProfile } from "app/core/types/enums.type";
import { UserService } from "app/core/user/user.service";
import { User } from "app/core/user/user.types";
import { Subject } from "rxjs";

@Component({
  selector: "feed-menu",
  templateUrl: "./menu.component.html"
})

export class FeedMenuComponent implements OnInit, OnDestroy {

  public profileEnum = UserProfile;
  public boolEnum = BoolEnum;  
  public modulesEnum = ModuleAdminEnum;
  
  loggedUser: User;
  
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  /**
   * Constructor
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _userService: UserService, private _router: Router) {}

  /**
   * On init
   */
  ngOnInit(): void {
    
    this._userService.updateProfile$.subscribe((message) => {
      this.reloadPage(message)
    });

    this.findUserStatus();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  signOut(): void {
   /* localStorage.removeItem("deviceId");
    localStorage.removeItem("products");*/
    localStorage.clear();
    this._router.navigate(['/sign-out']);
}

findUserStatus(){ 
  this._userService.findUserStatus().subscribe(
    (response) => {
      if (!response.isError) {
        if(response.data.length > 0){
          this.loggedUser = response.data[0];
          //console.log(localStorage.getItem("isMainUser"));
          //this.loggedUser.needModules = (response.data[0].profileId == this.profileEnum.profileIdAdmin);
          let modules = [];
          response.data[0].modules?.forEach(element => {
            modules.push(element.moduleId);
          });
          this.loggedUser.modules = modules;
          this._changeDetectorRef.markForCheck();   
        }
      }
    });
} 

reloadPage(option: ObserverUpdateData){
  switch(option){
      case ObserverUpdateData.profile:
          this.findUserStatus();           
      break;
  }
} 
}
