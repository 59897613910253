/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    
    { id: 'main', type: 'group', title: 'Principal'},  
    { id: 'feed', title: 'Publicaciones recientes', type: 'basic', link: '/feed' },
    { id: 'moments', title: 'Moments', type: 'basic', link: '/moments/recent' },
    { id: 'follows', title: 'Sigueindo', type: 'basic', link: '/contacts' },
    { id: 'badges', title: 'Insignias', type: 'basic', link: '/badges' },
    { id: 'categories', title: 'Categorías', type: 'basic', link: '/categories' },
    { id: 'companies', title: 'Vendedores', type: 'basic', link: '/companies' },
    { id: 'cart', title: 'Mi Carrito', type: 'basic', link: '/cart' },
    { id: 'orders', title: 'Mis Pedidos', type: 'basic', link: '/orders' },
    { id: 'divider', type: 'divider' },
    { id: 'admin', type: 'group', title: 'Administración'},
    { id: 'manager.badges', title: 'Insignias', type: 'basic', link: '/manager/badges'},
    { id: 'manager.categories', title: 'Categorías', type: 'basic', link: '/manager/categories'},
    { id: 'manager.subcategories', title: 'Subcategorías', type: 'basic', link: '/manager/subcategories'},
    { id: 'manager.dashboard', title: 'Estadisticas', type: 'basic', link: '/manager/dashboard'},
    { id: 'manager.products', title: 'Productos', type: 'basic', link: '/manager/products'},
    { id: 'manager.buyers', title: 'Compradores', type: 'basic', link: '/manager/buyers'},
    { id: 'manager.influencers', title: 'Influencers', type: 'basic', link: '/manager/influencers'},
    { id: 'manager.coupons', title: 'Cupones', type: 'basic', link: '/manager/coupons'},
    { id: 'manager.sellers', title: 'Vendedores', type: 'basic', link: '/manager/sellers'},
    { id: 'manager.sales', title: 'Pedidos', type: 'basic', link: '/manager/sales'},
    { id: 'manager.memberships', title: 'Membresías', type: 'basic', link: '/manager/memberships'},
    { id: 'manager.ranking', title: 'Ranking', type: 'basic', link: '/manager/ranking'},
    { id: 'manager.teams', title: 'Colaboradores', type: 'basic', link: '/manager/teams'},
    { id: 'manager.teams', title: 'Colaboradores', type: 'basic', link: '/manager/teams'},
    { id: 'divider', type: 'divider' },
    { id: 'account', type: 'group', title: 'Cuenta'},    
    { id: 'settings', title: 'Configuración', type: 'basic', link: '/settings'}
];
export const compactNavigation: FuseNavigationItem[] = [];

export const futuristicNavigation: FuseNavigationItem[] = [];

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: '',
        tooltip:'Inicio',
        type: 'basic',
        icon: 'assets/images/navigation/home.svg',
        iconSelected: 'assets/images/navigation/home_selected.svg',
        link: '/feed',
        hasBadge: '0'
    },
    {
        id: 'categories',
        title: '',
        tooltip: 'Categorías',
        type: 'basic',
        icon: 'assets/images/navigation/categories.svg',
        iconSelected: 'assets/images/navigation/categories_selected.svg',
        link: '/categories',
        hasBadge: '0'
    },
    {
        id: 'companies',
        title: '',
        tooltip: 'Vendedores',
        type: 'basic',
        icon: 'assets/images/navigation/companies.svg',
        iconSelected: 'assets/images/navigation/companies.svg',
        link: '/companies',
        hasBadge: '0'
    },
    {
        id: 'cart',
        title: '',
        tooltip: 'Mi Carrito',
        type: 'basic',
        icon: 'assets/images/navigation/cart.svg',
        iconSelected: 'assets/images/navigation/cart_selected.svg',
        link: '/cart',
        hasBadge: '1'
    },
    {
        id: 'shopping',
        title: '',
        tooltip : 'Mis Pedidos',
        type: 'basic',
        icon: 'assets/images/navigation/orders.svg',
        iconSelected: 'assets/images/navigation/orders_selected.svg',
        link: '/orders',
        hasBadge: '0'
    }
];
