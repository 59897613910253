import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { AuthService } from "app/core/auth/auth.service";
import { UserService } from '../user/user.service';

const END_POINT = `${environment.serviceURL}`;
@Injectable()
export class PushNotificationService {

  currentMessage = new BehaviorSubject<any>(null);
  private _token: BehaviorSubject<any> = new BehaviorSubject(null);
  private _chatNotification: BehaviorSubject<any> = new BehaviorSubject(null);
  private _pushNotification: BehaviorSubject<any> = new BehaviorSubject(null);
  private _requestNotification: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging,
    private _authService: AuthService,
    private _userService: UserService  ) {
  }

  /**
 * Getter for _chatNotification
 */
  get _chatNotification$(): Observable<any> {
    return this._chatNotification.asObservable();
  }

  setChatNotification(value) {
    this._chatNotification.next(value);
  }

  get _pushNotification$(): Observable<any> {
    return this._pushNotification.asObservable();
  }

  setPushNotification(value) {
    this._pushNotification.next(value);
  }
  
  get _requestNotification$(): Observable<any> {
    return this._requestNotification.asObservable();
  }

  setRequestNotification(value) {
    this._requestNotification.next(value);
  }  

  requestPermission() {
    const agent = window.navigator.vendor.toLowerCase()
    if(!agent.includes('apple')){
      if (window.Notification) {
        Notification.requestPermission((status) => {
          if(status !== 'denied'){
            this.angularFireMessaging.requestPermission.pipe(mergeMapTo(this.angularFireMessaging.tokenChanges)).subscribe({
              next: (v) => {
                localStorage.setItem("fireToken", v);
                const deviceId = localStorage.getItem("deviceId");
                const auth = localStorage.getItem("auth");
                if (deviceId !== null && deviceId != '' && auth != null && auth == '1') {
                  const json = { deviceId: deviceId, token: v }
                  this._authService.saveFireToken(json).subscribe(
                    (data) => {
                      console.log("saved fireToken: " + JSON.stringify(!data.isError));
                    }
                  );
                }
              },
              error: (e) => {
                console.log(e);
                console.info('informarle al usuaurio que debe activar las notificaciones');
              }
            });
          }
        });
      }
    }
  }

  receiveMessage() {
    
    this.angularFireMessaging.messages.subscribe({
      next: (payload) => {
        console.log(payload);
        if (payload['data'] == null) {
          return;
        }
        this._userService.findUserStatus().subscribe({
          next: (response) => {
            if (payload['data'].userId == response.data[0]['id']) {
              if (payload['data'].itemTypeId == '7') {
                this._chatNotification.next(response.data[0].chatUnReaded);
              } else {
                this._pushNotification.next(response.data[0].pushUnReaded);
                if (payload['data'].itemTypeId == '8') { 
                  this._requestNotification.next(response.data[0].pushUnReaded);
                }
              }
              this.currentMessage.next(payload['data']);
            }
          }
        });
      }
    });
  }



}