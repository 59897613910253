import { FuseAlertType } from '@fuse/components/alert';

export interface GenericAlert {
    title: string;
    type: FuseAlertType;
    message: string;
}

export interface CallbackAlert {
    (finished: boolean): void;
}

export interface CallbackFileCompress {
    (file: File): void;
}

export enum UserProfile {
    profileIdAdmin = 1,
    profileIdSeller = 2,
    profileIdBuyer = 3,
    profileIdInfluencer = 4
}

export enum StatusEnum {
    active = "1",
    delete = "2",
    pending = "3",
    pendingPayment = "4",
    paid = "5",
    sent = "6",
    delivered = "10"
}

export enum ShippingTypeEnum {
    enviaYa = "1",
    own = "2",
    third = "3",
    store = "4"
}

export enum DepotTypeEnum {
    store = "1",
    internal = "2",
    external = "3",
    na = "4"
}

export enum LogisticsTypeEnum {
    local = "1",
    national = "2",
    international = "3",
    na = "4"
}

export enum CapacityTypeEnum {
    minLevel = "1",
    mediumLevel = "2",
    maxLevel = "3",
    na = "4"
}

export enum FileTypeEnum {
    image = "1",
    video = "2"
}

export enum RegisterCompanyStep {
    infoStep = 0,
    sellerType = 1,
    membership = 2,
    paymentMethod = 3,
    general = 4,
    fiscal = 5,
    financial = 6,
    commercial = 7,
    logistics = 8,
    documents = 9,
    references = 10
}

export enum SellerType {
    individual = 1,
    entrepreneur = 2,
    merchants = 3,
    startup = 4,
    pyme = 5,
    company = 6,
    corporative = 7
}

export enum PaymentMethod {
    card = 1,
    oxxo = 2
}

export enum AddressType {
    location = 1,
    deliver = 2,
    tax = 3,
    origin = 4
}

export enum ScheduledType {
    unavailable = '1',
    always = '2',
    special = '3'
}

export enum DayNumber{
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
    sunday = 7
}

export enum StepUpdateAccount {
    profile = 1,
    business = 2,
    financial = 3,
    commercials = 4,
    logistics = 5,
    identity = 6
}

export enum MembershipStatus {
    nomembership = 1,
    active = 2,
    pending = 3,
    canceledUser = 4,
    canceledSystem = 5
}  


export enum BoolEnum {
    yes = 1,
    no = 0
}

export enum FilterStoreEnum {
    all = 1,
    nearby = 2,
    hasPickup = 3
}


export enum NotificationTypeEnum {
    nearby = 2,
    hasPickup = 3,
    requestTeam = 10,
    publishPost = 12,
    publishMoment = 13,
    requestFollow = 14,
    responseFollow = 15,
    salesApproved = 16,
    salesRejected = 17,
    addBadge = 18,
    removeBadge = 19
}


export enum ObserverUpdateData {
    profile = 1,
    device = 2,
    collaborators = 3
}

export enum ModuleAdminEnum {
    badge = '1',
    categories = '2',
    subcategories = '3',
    statistics = '4',
    buyers = '5',
    influencers = '6',
    sellers = '7',
    memberships = '8',
    ranking = '9'
}

export enum TypeMomentEnum {
    text = 1,
    image = 2,
}


export enum PostTypeEnum {
    text = '5',
    image = '6',
    product = '7',
    moment = '9'
}

export enum ProductTypeId{
    product = '1',
    service = '2',
    digital = '3',    
}