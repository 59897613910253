// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    serviceURL: '/api',
    keyToken: 'su7h@02',
    facebookId: '328096445784038',
    googleId: '18713434989-k779tf0b7ccddmdbc2gp217fhk3u9s9d.apps.googleusercontent.com',
    conekta: 'key_CKqySR5fPHjfxxDc4Eiq46Q',
    googleKey: 'AIzaSyDTTcl08qEhM2cxs04l5j7ifcpNY5V0OLI',
    accessKeyId: "AKIAS6XAIGP3U3SBHH63",
    secretAccessKey: "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",
    region: "us-east-1",
    bucket: "surthex-media-develop",
    firebaseConfig: {
        apiKey: "AIzaSyCAX0fUOFoZ0YtyKw3YHtnn7D7BgJCVp5E",
        authDomain: "surthex-ab091.firebaseapp.com",
        projectId: "surthex-ab091",
        storageBucket: "surthex-ab091.appspot.com",
        messagingSenderId: "291818402983",
        appId: "1:291818402983:web:376045807b1a5cd8059782",
        measurementId: "G-VKBWZTG03Q"
    }    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
