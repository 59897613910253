import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations/public-api';
import { FeedService } from '../../../core/services/feed.service';
import { UploadAWSService } from 'app/core/services/upload.service';
import { UserProfile } from 'app/core/types/enums.type';
import { Router } from '@angular/router';

@Component({
    selector     : 'search',
    templateUrl  : './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'fuseSearch',
    animations   : fuseAnimations
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy
{
    public userProfile = UserProfile
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    resultSets: any[];
    searchControl: FormControl = new FormControl();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2,
        private _uploadService: UploadAWSService,
        private _feedService: FeedService, 
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any
    {
        return {
            'search-appearance-bar'  : this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened'          : this.opened
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef){
        if ( value ){
            setTimeout(() => {
                value.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void{
        if ( 'appearance' in changes ){
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void{
        // Subscribe to the search field value changes
        this.searchControl.valueChanges.pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {
                    if ( !value || value.length < this.minLength ){
                        this.resultSets = null;
                    }
                    return value;
                }),
                filter(value => value && value.length >= this.minLength)
            ).subscribe((value) => {
                this._feedService.generalSearch(value).subscribe((response: any) => {
                    if(response.isError){
                        this.resultSets = null;    
                    }else{
                        this.resultSets = response.data;    
                    }
                    this.search.next(response);
                });
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void{
        // Listen for escape to close the search
        // if the appearance is 'bar'
        if ( this.appearance === 'bar' ){
            // Escape
            if ( event.code === 'Escape' ){
                // Close the search
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void{
        // Return if it's already opened
        if ( this.opened ){
            return;
        }
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void{
        // Return if it's already closed
        if ( !this.opened ){
            return;
        }
        this.searchControl.setValue('');
        this.opened = false;
    }

    downloadFile(keyFileName) {
        if(keyFileName != null && keyFileName != ''){
          const url = this._uploadService.urlFile(keyFileName);
          return url;
        }
        return null; 
    }

    openProfile(nickname: String){
        this._router.navigate([`/feed/${nickname}`]);
    } 
    
    openProduct(nickname: String){
        this._router.navigate([`/product/${nickname}`]);
    } 

    openCategory(nickname: String){
        this._router.navigate([`/categories/${nickname}`]);
    }     

    openSubCategory(nickname: String){
        this._router.navigate([`/store/subcategory/${nickname}`]);
    }
 
}
