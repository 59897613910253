import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from './core/services/push-notification.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit{

    message: any;
    /**
     * Constructor
     */
    constructor(private notificationService: PushNotificationService){

    }

    ngOnInit(){
        this.notificationService.requestPermission();
        this.notificationService.receiveMessage();   
        this.message = this.notificationService.currentMessage;    
    }
}
