import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { PushNotificationService } from 'app/core/services/push-notification.service';
import { FeedService } from 'app/core/services/feed.service';
import { UserService } from 'app/core/user/user.service';
import { UserProfile } from 'app/core/types/enums.type';
import { NotificationTypeEnum, ObserverUpdateData, StatusEnum } from 'app/core/types/enums.type';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent implements OnInit, OnDestroy {

    profileEnum = UserProfile;
    isScreenSmall: boolean;
    navigation: Navigation;
    chatNotification = 0;
    pushNotification = 0;
    cartProductBadge = 0;
    profileId = null;
    isBusinessAuth = false;

    currentURL = null;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public statusEnum = StatusEnum;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _pushNotificationService: PushNotificationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _feedService: FeedService,
        private _userService: UserService,
        private _notificationService: PushNotificationService,
        private _transloco: TranslocoService,
    ) {
    }

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.currentURL = this._router.url;
        this._changeDetectorRef.markForCheck();
        this._router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.currentURL = event.url;
                this._changeDetectorRef.markForCheck();
            }
        });

        // Subscribe to navigation data
        this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
            this.navigation = navigation;
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
            this.isScreenSmall = !matchingAliases.includes('md');
        });

        this._userService.findUserStatus().pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (response) => {
                if (response.isError) {
                    this.chatNotification = Number(0);
                    this.pushNotification = Number(0);
                    this.isBusinessAuth = false;
                    this.profileId = null;
                } else {
                    this.profileId = response.data[0].profileId;
                    this.isBusinessAuth = (response.data[0].isBusinessAuth == '1');
                    this.chatNotification = Number(response.data[0].chatUnReaded);
                    this.pushNotification = Number(response.data[0].pushUnReaded);
                    this._pushNotificationService.setChatNotification(this.chatNotification);
                    this._pushNotificationService.setPushNotification(this.pushNotification);
                }
            }, error: (e) => {
                console.log(e);
            }
        });

        // SUBSCRIBE TO new notifications chat
        this._pushNotificationService._chatNotification$.pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
            this.chatNotification = Number(result);
            this._changeDetectorRef.markForCheck();
        });

        // SUBSCRIBE TO new notifications others
        this._pushNotificationService._pushNotification$.pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
            this.pushNotification = Number(result);
            this._changeDetectorRef.markForCheck();
        });

        // Suscribe cartNumber
        this._feedService._cartProductUpdate$.pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
            this.cartProductBadge = Number(result);
            this._changeDetectorRef.markForCheck();
        });

        this.cartProductBadge = this._feedService.getItemsNumberCart();
        this._changeDetectorRef.markForCheck();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if (navigation) {
            navigation.toggle();
        }
    }

    openchat(): void {
        this._router.navigate([`/chat`]);
    }

    changeToSeller() {
        this._userService.changeToSeller().subscribe({
            next: (response) => {
                this.profileId = this.profileEnum.profileIdSeller;
            }
        });
    }

    markAllReaded() {
        /* this._notificationService.setPushNotification(0);
                 this._changeDetectorRef.markForCheck();*/
        console.log("poner en cero las notificaciones");

        const json = {
            "notificationId": "0",
            "deviceId": localStorage.getItem('deviceId'),
            "statusId": this.statusEnum.active
        }

        this._userService.updateNotification(json).pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (response) => {
                if (response.isError) {
                    const msg = this._transloco.translateObject('common.generic_error');
                } 
                
            }, error: (e) => {
                const msg = this._transloco.translateObject('feed.server_errors');
            }
        });
    }
}
