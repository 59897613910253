import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

const END_POINT = `${environment.serviceURL}`;

@Injectable({
    providedIn: 'root'
})
export class FeedService {

    private _cartProductUpdate: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient) {
        //this.userLogued = this.userService.userLogued;
    }

    get _cartProductUpdate$(): Observable<any> {
        return this._cartProductUpdate.asObservable();
    }
    
    setCartProductUpdate(value: any){
        this._cartProductUpdate.next(value);
    }    

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    findUserById(id): Observable<any> {
        return this._httpClient.get<any>(`${END_POINT}/v1/user/${id}`);
    }
    findUserByNickname(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/nickname`, json);
    } 
    udpateAddress(id: string, address: any): Observable<any> {
        return this._httpClient.put(`${END_POINT}/v1/address/${id}`, address);
    }
    udpateMedia(id: string, json: any): Observable<any> {
        return this._httpClient.put(`${END_POINT}/v1/user-media/${id}`, json);
    }   
    
    createPost(post: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/post`, post);
    } 
    
    deletePost(post: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/delete-post`, post);
    }     
    
    reloadFeed(data: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/reload-feed`, data);
    } 
    
    reloadMoments(): Observable<any> {
        const data = {deviceId: localStorage.getItem('deviceId')};
        return this._httpClient.post(`${END_POINT}/v1/reload-moments`, data);
    }     
    
    reloadFeedMe(data: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/reload-feedme`, data);
    } 
    
    reloadFeedDetail(data: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/reload-feed-detail`, data);
    }     
    
    momentsDetail(item: any): Observable<any> {
        const data = {deviceId: localStorage.getItem('deviceId'), item: item};
        return this._httpClient.post(`${END_POINT}/v1/moments-detail`, data);
    }

    like(data: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/like`, data);
    }   
    
    reloadComment(data: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/reload-comment`, data);
    }    
    
    findUserRequest(id): Observable<any> {
        return this._httpClient.get<any>(`${END_POINT}/v1/user-request/${id}`);
    }    
 
    findFriendsByNickname(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/friends-nickname`, json);
    }  
    
    findBadgesByNickname(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/badges-nickname`, json);
    }      
    
    findBusinessByNickname(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/business-nickname`, json);
    } 

    createUserBuyer(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/create-user-buyer`, json);
    }    

    createUserBusiness(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/create-user-business`, json);
    }

    findUserAccount(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/get-user-business`, json);
    }
    
    updateUserBusiness(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/update-user-business`, json);
    }

    findUserAccountDetail(json: {id: string, deviceId: string, type: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/get-user-business-detail`, json);
    }

    findStoreFriendly(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/get-store-friendly`, json);
    }    

    findUserMembershipPayment(json): Observable<any>{
        return this._httpClient.post<any>(`${END_POINT}/v1/get-user-membership-payments`, json);
    }

    resendInvoice(json): Observable<any>{
        return this._httpClient.post<any>(`${END_POINT}/v1/resend-invoice`, json);
    }    

    generalSearch(query): Observable<any>{
        const json = {
            deviceId: localStorage.getItem("deviceId"),
            query: query
        };        
        return this._httpClient.post<any>(`${END_POINT}/v1/general-search`, json)
    }

    blankCart(): void{
        localStorage.removeItem("products");
    }

    getItemsNumberCart(): any{
        var products = [];
        let str = localStorage.getItem("products");
        if(str == null || str == ''){ 
            products = [];      
        }else{
            products = JSON.parse(str);
        }

        return products.length;
      }

    addToCart(item: any): boolean{
        var response = false;

        var products = [];
        let str = localStorage.getItem("products");
        if(str == null || str == ''){ 
            products = [];      
        }else{
            products = JSON.parse(str);
        }
        if(products.length == 0 || (products.length > 0 && products[0].storeId == item.storeId)){
            var url = (item.media.length > 0) ? item.media[0].url : null;
            let product = {
                id: item.id,
                stock: item.stock,
                nbr: item.nbr,
                media: url,
                name: item.name,
                color: item.color,
                price: item.hasDiscount == '1' ? item.discountPrice : item.normalPrice,
                size: item.size,
                storeId: item.storeId
            };
            products.push(product);
            localStorage.setItem("products", JSON.stringify(products));
            this.setCartProductUpdate(products.length);
            response = true;
        }else{
            response = false;
        }
        return response;
      }

      removeToCart(products: any){
        localStorage.setItem("products", JSON.stringify(products));
        this.setCartProductUpdate(products.length);
      }

}
