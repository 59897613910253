import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, RouterModule } from "@angular/router";
import { MarkdownModule } from "ngx-markdown";
import { FuseModule } from "@fuse";
import { FuseConfigModule } from "@fuse/services/config";
import { FuseMockApiModule } from "@fuse/lib/mock-api";
import { CoreModule } from "app/core/core.module";
import { appConfig } from "app/core/config/app.config";
import { mockApiServices } from "app/mock-api";
import { LayoutModule } from "app/layout/layout.module";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { GoogleMapsModule } from "@angular/google-maps";
import { SafeHtmlPipe } from './safe-html.pipe';
import { PushNotificationService } from './core/services/push-notification.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { environment } from '../environments/environment.prod';
import { AsyncPipe } from "@angular/common";
import { InputMaskModule } from '@ngneat/input-mask';
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localeEs, "es");

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
  useHash: true
};

@NgModule({
  declarations: [AppComponent, SafeHtmlPipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),
    CoreModule,
    LayoutModule,
    MarkdownModule.forRoot({}),
    InputMaskModule.forRoot({
      isAsync: false,
      inputSelector: 'input'
    }),    
    GoogleMapsModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [PushNotificationService, AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
