import { Injectable } from "@angular/core";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from 'environments/environment';

const accessKeyId = `${environment.accessKeyId}`;
const secretAccessKey = `${environment.secretAccessKey}`;
const region = `${environment.region}`;
const bucketStr = `${environment.bucket}`;

@Injectable({
  providedIn: "root",
})
export class UploadAWSService {
  constructor() {}

  uploadFile(keyFileName, file, callback) {
    const contentType = file.type;
    const bucket = new S3({ accessKeyId: accessKeyId, secretAccessKey: secretAccessKey, region: region });
    const params = {
      Bucket: bucketStr,
      Key: keyFileName,
      Body: file,
      ContentType: contentType
    };
    
    bucket.upload(params, function (error, data) {
        callback(error, data);
    });
    
  }

  downloadFile(keyFileName, callback) {
    const bucket = new S3({ accessKeyId: accessKeyId, secretAccessKey: secretAccessKey, region: region });
    const params = {
      Bucket: bucketStr,
      Key: keyFileName
    };

    bucket.getObject(params, function (error, data) {
      callback(error, data);
      }
    );    
  }

  urlFile(keyFileName){
      const signedUrlExpireSeconds = 3600 * 1
      const bucket = new S3({ accessKeyId: accessKeyId, secretAccessKey: secretAccessKey, region: region, signatureVersion: 'v4' });
      const params = {
        Bucket: bucketStr,
        Key: keyFileName,
        Expires: signedUrlExpireSeconds
      };      
      const url = bucket.getSignedUrl('getObject', params)      
      return url;
  }

}
