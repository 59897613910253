<ng-container *transloco="let t">
    <div class="hidden sm:block overflow-y-auto h-screen space-y-8 itz-fix-scroll">
        <div class="py-6 bg-card shadow rounded-lg h-fit">
            <div class="flex flex-col sm:flex-row items-start justify-between px-6">
                <div class="text-lg font-bold tracking-tight leading-6 truncate itz-links">{{'side_menu.main' | transloco}}
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-2"></div>
            </div>
            <div class="flex flex-col flex-auto mt-6">
                <ng-container *ngIf="loggedUser && loggedUser.profileId != profileEnum.profileIdAdmin">
                    <a [routerLink]="['/moments/recent']" class="py-2 itz-link-menu px-6">
                        <div class="flex items-center cursor-pointer">
                            <img class="w-10 cursor-pointer" src="assets/images/menu/ic_moments.svg">
                            <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.moments' | transloco}}</label>
                        </div>
                    </a>
                </ng-container>
                <ng-container *ngIf="loggedUser">
                    <a [routerLink]="['/contacts']" class="py-2 itz-link-menu px-6">
                        <div class="flex items-center cursor-pointer">
                            <img class="w-10 cursor-pointer" src="assets/images/menu/ic_following.svg">
                            <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.followings' | transloco}}</label>
                        </div>
                    </a>
                </ng-container>
                <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdSeller && loggedUser.hasBadge == boolEnum.yes">
                    <a [routerLink]="['/badges']" class="py-2 itz-link-menu px-6">
                        <div class="flex items-center cursor-pointer">
                            <img class="w-10 cursor-pointer" src="assets/images/menu/ic_badge.svg">
                            <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.badges' | transloco}}</label>
                        </div>
                    </a>
                </ng-container>
                <ng-container *ngIf="loggedUser">
                    <a [routerLink]="['/feed', loggedUser.nickname]" class="py-2 itz-link-menu px-6">
                        <div class="flex items-center cursor-pointer">
                            <img class="w-10 cursor-pointer" src="assets/images/menu/ic_profile.svg">
                            <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.profile' | transloco}}</label>
                        </div>
                    </a>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="loggedUser && (loggedUser.profileId == profileEnum.profileIdAdmin || loggedUser.profileId == profileEnum.profileIdSeller)">
            <div class="py-6 bg-card shadow rounded-lg h-fit">
                <div class="flex flex-col sm:flex-row items-start justify-between px-6">
                    <div class="text-lg font-bold tracking-tight leading-6 truncate itz-links">{{'side_menu.manage' | transloco}}
                    </div>
                    <div class="mt-3 sm:mt-0 sm:ml-2"></div>
                </div>
                <div class="flex flex-col flex-auto mt-6">
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.badge)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/badges']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_badges.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.badges' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.categories)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/categories']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_categories.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.categories' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.subcategories)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/subcategories']" href="#" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_subcategories.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.subcategories' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && (loggedUser.profileId == profileEnum.profileIdAdmin || loggedUser.hasDashboard == boolEnum.yes) && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.statistics)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/dashboard']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_statistics.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.statistics' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdSeller && loggedUser.productsNbr > 0">
                        <a [routerLink]="['/manager/products']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_product.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.products' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.buyers)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/buyers']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_buyer.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.buyers' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.influencers)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/influencers']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_influencer.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.influencers' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdSeller && loggedUser.hasCoupons == boolEnum.yes">
                        <a [routerLink]="['/manager/coupons']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_coupons.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.coupons' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.sellers)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/sellers']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_seller.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.sellers' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdSeller && loggedUser.hasSaleFlow == boolEnum.yes">
                        <a [routerLink]="['/manager/sales']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_invoice.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.sales' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.memberships)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/memberships']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_membership.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.memberships' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin && ((loggedUser.needModules && loggedUser.modules.includes(modulesEnum.ranking)) || !loggedUser.needModules)">
                        <a [routerLink]="['/manager/ranking']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_team.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.ranking' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdSeller && loggedUser.usersNbr > 0">
                        <a [routerLink]="['/manager/teams']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_team.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.team' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="loggedUser && loggedUser.profileId == profileEnum.profileIdAdmin">
                        <a [routerLink]="['/manager/teams']" class="py-2 cursor-pointer itz-link-menu px-6">
                            <div class="flex items-center cursor-pointer">
                                <img class="w-4 cursor-pointer" src="assets/images/menu/ic_team.svg">
                                <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.team' | transloco}}</label>
                            </div>
                        </a>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <div class="py-6 bg-card shadow rounded-lg h-fit">
            <div class="flex flex-col sm:flex-row items-start justify-between px-6">
                <div class="text-lg font-bold tracking-tight leading-6 truncate itz-links">{{'side_menu.account' | transloco}}</div>
                <div class="mt-3 sm:mt-0 sm:ml-2"></div>
            </div>
            <div class="flex flex-col flex-auto mt-6">
                <a [routerLink]="['/settings']" class="py-2 itz-link-menu px-6">
                    <div class="flex items-center cursor-pointer">
                        <img class="w-4 cursor-pointer" src="assets/images/menu/ic_settings.svg">
                        <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.settings' | transloco}}</label>
                    </div>
                </a>
                <a (click)="signOut()" class="py-2 itz-link-menu px-6">
                    <div class="flex items-center cursor-pointer">
                        <img class="w-4 cursor-pointer" src="assets/images/menu/ic_logout.svg">
                        <label class="px-2 font-semibold itz-links cursor-pointer">{{'side_menu.logout' | transloco}}</label>
                    </div>
                </a>
            </div>
        </div>
    </div>
</ng-container>