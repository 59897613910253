<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-32 pt-6 px-8">
                <img class="w-16" src="assets/images/logo/logo.svg" alt="Surthex">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 h-screen overflow-hidden">
    <!-- Header -->
    <div class="sticky flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 bg-card shadow">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <a routerLink="/">
                        <img class="dark:hidden w-24" src="assets/images/logo/logo-surthex-char.svg">
                        <img class="hidden dark:flex w-24" src="assets/images/logo/logo-surthex-char.svg">
                    </a>
                </div>
                <img class="flex lg:hidden w-8" src="assets/images/logo/logo.svg">
            </div>
            <search [appearance]="'basic'"></search>
            <!-- Horizontal navigation -->
            <div class="flex space-x-2 ml-4">
                <button mat-icon-button [routerLink]="'/feed'" matTooltip="Feed">
                    <img [src]="(currentURL == '/feed') ? 'assets/images/navigation/home_selected.svg' : 'assets/images/navigation/home.svg'">
                </button>
                <button mat-icon-button [routerLink]="'/categories'" matTooltip="Categorías">
                    <img [src]="(currentURL == '/categories') ? 'assets/images/navigation/categories_selected.svg' : 'assets/images/navigation/categories.svg'">
                </button>
                <button mat-icon-button [routerLink]="'/companies'" matTooltip="Vendedores">
                    <img [src]="(currentURL == '/companies') ? 'assets/images/navigation/companies.svg' : 'assets/images/navigation/companies.svg'">
                </button>
                <button mat-icon-button [routerLink]="'/cart'" class="relative" matTooltip="Carrito">
                    <ng-container *ngIf="cartProductBadge > 0">  
                        <span class="absolute top-0 right-0">
                            <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 rounded-full bg-red-600 text-red-50 text-xs font-medium">
                               {{cartProductBadge}}
                            </span>
                        </span>
                    </ng-container>
                    <img [src]="(currentURL == '/cart') ? 'assets/images/navigation/cart_selected.svg' : 'assets/images/navigation/cart.svg'">
                </button>
                <button mat-icon-button [routerLink]="'/orders'" matTooltip="Pedidos">
                    <img [src]="(currentURL == '/orders') ? 'assets/images/navigation/orders_selected.svg' : 'assets/images/navigation/orders.svg'">
                </button>
            </div>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <button mat-icon-button (click)="openchat()" matTooltip="Chat" aria-label="Chatea con tus contactos">
                <ng-container *ngIf="chatNotification > 0">  
                    <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
                        <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-600 text-red-50 text-xs font-medium">
                           {{chatNotification}}
                        </span>
                    </span>
                </ng-container>
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt'" class="surthex-green"></mat-icon>
            </button>
            <notifications [unreadCount]="pushNotification" (click)="markAllReaded()"></notifications>

            <ng-container *ngIf="profileId == profileEnum.profileIdBuyer || profileId == profileEnum.profileIdInfluencer">
                <button mat-icon-button (click)="changeToSeller()" matTooltip="Vendedor" aria-label="Convertiertete en vendedor y disfruta de los beneficios que tenemos para ti.">
                    <mat-icon [svgIcon]="'heroicons_outline:sparkles'" class="surthex-green"></mat-icon>
                </button>
            </ng-container>

            <user></user>
        </div>
    </div>

    <!-- Content ceumibeutrise-2854@yopmail.com -->
    <div class="flex flex-col flex-auto w-full">

        <ng-container *ngIf="profileId && !isBusinessAuth">
            <div class="flex surthex-blue-rev items-center justify-center text-white text-center py-1">
                <mat-icon [svgIcon]="'heroicons_outline:information-circle'" class="surthex-gray-strong icon-size-4"></mat-icon>
                <span class="surthex-gray-strong">Nuestro equipo esta validando tu perfil, pronto podrás gozar de nuestros beneficios</span>
            </div>
        </ng-container>

        <div class="grid grid-cols-1 sm:grid-cols-12 md:grid-cols-12 gap-6 sm:gap-8 w-full min-w-0 px-6 sm:px-8 py-6 sm:py-8">
            <div class="col-span-1 sm:col-span-3 flex flex-col flex-auto overflow-hidden h-screen hidden sm:block">
                <feed-menu></feed-menu>
            </div>
            <div class="col-span-1 sm:col-span-9 flex flex-col flex-auto overflow-hidden h-screen">
                <router-outlet *ngIf="true"></router-outlet>
            </div>
        </div>
    </div>
</div>