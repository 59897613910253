<ng-container *transloco="let t">
    <!-- Button -->
    <button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-8 h-8 border-2 rounded-full object-cover" [ngClass]="user?.profileId == '2' ? 'itz-border-seller' : 'itz-border-user'" *ngIf="user?.avatar" [src]="downloadFile(user?.avatar)" [alt]="user?.name">
        <img class="w-8 h-8 border-2 rounded-full object-cover" [ngClass]="user?.profileId == '2' ? 'itz-border-seller' : 'itz-border-user'" *ngIf="!user?.avatar" src="assets/images/feed/ic_empty_profile.svg" [alt]="user?.name">
    </span>
</button>

    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>{{'menu.wellcome' | transloco}}</span>
                <span class="mt-1.5 text-md font-medium" *ngIf="user">@{{user?.nickname}}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="profile()">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>{{'menu.profile' | transloco}}</span>
        </button>
        <button mat-menu-item (click)="account()">
            <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
            <span>{{'menu.setting' | transloco}}</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
            <span>{{'menu.signout' | transloco}}</span>
        </button>
    </mat-menu>

</ng-container>