import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { AuthUtils } from '../auth/auth.utils';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ObserverUpdateData } from '../types/enums.type';

const END_POINT = `${environment.serviceURL}`;

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: User;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user = value;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get user$(): Observable<User> {
        return of(this._user);
    }

    get userLogued(): User {
        return this._user;
    }

    private _updateProfile = new Subject<ObserverUpdateData>();
    updateProfile$ = this._updateProfile.asObservable();
    sendUpdateProfile(message: ObserverUpdateData) {
        this._updateProfile.next(message);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        const user: User = AuthUtils.getUserByToken(localStorage.getItem('userToken') ?? '');
        return of(user);
    }

    getAddresses(deviceId: any): Observable<any> {
        return this._httpClient.get<any>(`${END_POINT}/v1/address/${deviceId}`);
    } 
    
    getOrders(deviceId: any): Observable<any> {
        const json =  {deviceId: deviceId};
        return this._httpClient.post<any>(`${END_POINT}/v1/my-orders`, json);
    } 
    
    getOrderDetail(deviceId: any, id: any): Observable<any> {
        const json =  {deviceId: deviceId, id: id};
        return this._httpClient.post<any>(`${END_POINT}/v1/my-orders-detail`, json);
    }     
    
    findCompaniesWeb(json: {deviceId: any, orderId: any}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/companies-web`, json);
    } 
    
    userRequest(json: {followUserId: string, deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/user-request`, json);
    }  
    
    responseUserRequest(json: {id: string, statusId: string, deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/response-user-request`, json);
    }
    
    findFollowing(json: {deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/following`, json);
    }

    findFollowingNickname(json: {deviceId: string, nickname: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/following-nickname`, json);
    }    
    
    deleteFollowing(json: {id: string, deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/delete-following`, json);
    }
    
    findBuyers(): Observable<any> {
        return this._httpClient.get<any>(`${END_POINT}/v1/buyers`);
    } 
    
    findInfluencers(): Observable<any> {
        return this._httpClient.get<any>(`${END_POINT}/v1/influencers`);
    }     

    blockUser(json: {deviceId: string, userId: string, blocked: number}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/block-user`, json);
    }    
    
    findCoupons(json: {deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/coupons`, json);
    }     
    
    createCoupon(json: any): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/coupon`, json);
    } 
    
    deleteCoupon(json: any): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/delete-coupon`, json);
    }   
    
    findOrders(json: {deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/seller-orders`, json);
    } 
    
    findOrderDetail(json: {deviceId: string, id: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/seller-orders-detail`, json);
    }     

    findUserStatus(): Observable<any> {
        const json = {
            deviceId: localStorage.getItem("deviceId"),
            userDeviceId: localStorage.getItem("userDeviceId")
        };
        return this._httpClient.post<any>(`${END_POINT}/v1/user-status`, json);
    } 

    changeToSeller(): Observable<any>{
        const json = {
            deviceId: localStorage.getItem("deviceId")
        };
        return this._httpClient.post<any>(`${END_POINT}/v1/change-to-seller`, json);
    }

    findTeamBusiness(json: {deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/get-team-business`, json);
    } 
    
    searchTeamBusiness(json: {deviceId: string, query: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/team-business-search`, json);
    }  

    addTeamAdmin(json: {deviceId: string, businessId: string, userId: any, permissions: any}){
        return this._httpClient.post<any>(`${END_POINT}/v1/add-team-admin`, json);
    } 
    
    updateTeamAdmin(json: {deviceId: string, teamBusinessId: string, permissions: any}){
        return this._httpClient.post<any>(`${END_POINT}/v1/update-team-admin`, json);
    }     

    addTeamBusiness(json: {deviceId: string, businessId: string, users: []}){
        return this._httpClient.post<any>(`${END_POINT}/v1/add-team-business`, json);
    }

    acceptTeamBusiness(json: {deviceId: string, id: string}){
        return this._httpClient.post<any>(`${END_POINT}/v1/accept-team-business`, json);
    } 
    
    removeTeamBusiness(json: {deviceId: string, id: string}){
        return this._httpClient.post<any>(`${END_POINT}/v1/delete-team-business`, json);
    }

    findNotification(json: {deviceId: string}){
        return this._httpClient.post<any>(`${END_POINT}/v1/get-notification`, json);
    }  
    
    findCollaborations(json: {deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/get-collaborations`, json);
    } 

    changeToCollaborator(json: {deviceId: string, teamBusinessId: string, businessId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/change-collaborator`, json);
    } 
    
    revertToCollaborator(json: {deviceId: string, userDeviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/revert-collaborator`, json);
    } 
    
    findRanking(json: {deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/get-ranking`, json);
    }     
    
    findDashboard(json: {deviceId: string}): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/get-dashboard`, json);
    }  

    deliveryGuide(json: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/delivery-guide`, json);
    } 

    pendingPayment(json: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/pending-payment`, json);
    }
    
    updateSaleStatus(json: any): Observable<any> {
        return this._httpClient.post(`${END_POINT}/v1/update-status-sales`, json);
    }       
    
    updateNotification(json): Observable<any> {
        return this._httpClient.post<any>(`${END_POINT}/v1/mark-notification`, json);
    }
}
