<!-- Bar search -->
<!--
<ng-container *ngIf="appearance === 'bar'">
    <button mat-icon-button *ngIf="!opened" (click)="open()">
        <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
    </button>
    <div class="absolute inset-0 flex items-center flex-shrink-0 z-99 bg-card" *ngIf="opened" @slideInTop @slideOutTop>
        <mat-icon class="absolute ml-6 sm:ml-8" [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input class="w-full h-full px-16 sm:px-18" [formControl]="searchControl" [matAutocomplete]="matAutocomplete" [placeholder]="'Search for a page or a contact'" (keydown)="onKeydown($event)" #barSearchInput>
        <mat-autocomplete class="max-h-128 sm:px-2 border-t rounded-b shadow-md" [disableRipple]="true" #matAutocomplete="matAutocomplete">
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent" *ngIf="resultSets && resultSets.data && resultSets.data.nbr === 0">
                ¡No se encontrarón resultados!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center mt-2 px-2">
                    <span class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
                    <mat-option class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover" [routerLink]="result.link">
                        <ng-container *ngIf="resultSet.id === 'contacts'">
                            <ng-container *ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="resultSet.id === 'pages'">
                            <ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="resultSet.id === 'tasks'">
                            <ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
        <button class="absolute top-1/2 right-5 sm:right-7 flex-shrink-0 w-10 h-10 -mt-5" mat-icon-button (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
</ng-container>
-->

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80">
        <mat-form-field class="fuse-mat-no-subscript w-full itz-search-rounded">
            <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
            <input matInput [formControl]="searchControl" [matAutocomplete]="matAutocomplete" [placeholder]="'Búsqueda'" (keydown)="onKeydown($event)">
        </mat-form-field>
        <mat-autocomplete class="max-h-128 mt-1 rounded" [disableRipple]="true" #matAutocomplete="matAutocomplete">
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent" *ngIf="resultSets && resultSets.length === 0">
                ¡No se encontrarón resultados!
            </mat-option>

            <ng-container *ngIf="resultSets && resultSets.length > 0">
                <ng-container *ngFor="let result of resultSets">
                    <mat-optgroup class="flex items-center mt-2 px-2">
                        <span class="text-sm font-semibold tracking-wider text-secondary">{{result.label}}</span>
                    </mat-optgroup>
                    <ng-container *ngFor="let row of result.items">
                        <ng-container *ngIf="row.itemTypeId === 'users'">
                            <mat-option class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover" (click)="openProfile(row.nickname)">
                                <ng-container *ngTemplateOutlet="contactResult; context: {$implicit: row}"></ng-container>
                            </mat-option>
                        </ng-container>

                        <ng-container *ngIf="row.itemTypeId === 'products'">
                            <mat-option class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover" (click)="openProduct(row.friendly)">
                                <ng-container *ngTemplateOutlet="productResult; context: {$implicit: row}"></ng-container>
                            </mat-option>
                        </ng-container>

                        <ng-container *ngIf="row.itemTypeId === 'categories'">
                            <mat-option class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover" (click)="openCategory(row.friendly)">
                                <ng-container *ngTemplateOutlet="categoryResult; context: {$implicit: row}"></ng-container>
                            </mat-option>
                        </ng-container>

                        <ng-container *ngIf="row.itemTypeId === 'subcategories'">
                            <mat-option class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover" (click)="openSubCategory(row.friendly)">
                                <ng-container *ngTemplateOutlet="categoryResult; context: {$implicit: row}"></ng-container>
                            </mat-option>
                        </ng-container>

                    </ng-container>

                </ng-container>
            </ng-container>
        </mat-autocomplete>
    </div>
</ng-container>

<!-- Contact result template -->
<ng-template #contactResult let-result>
    <div class="flex items-center">
        <img class="min-w-10 w-10 min-h-10 h-10 border-2 rounded-full object-cover" *ngIf="!result.avatar" [ngClass]="result.profileId == userProfile.profileIdSeller ? 'itz-border-seller' : 'itz-border-user'" src="assets/images/feed/ic_empty_profile.svg" [alt]="result.name">
        <img class="min-w-10 w-10 min-h-10 h-10 border-2 rounded-full object-cover" *ngIf="result.avatar" [ngClass]="result.profileId == userProfile.profileIdSeller ? 'itz-border-seller' : 'itz-border-user'" [src]="downloadFile(result.avatar)" [alt]="result.name">
        <div class="flex flex-col leading-normal ml-3 truncate">
            <span [innerHTML]="result.name"></span>
            <span class="itz-text-cool-grey">@{{result.nickname}}</span>
        </div>
    </div>
</ng-template>

<ng-template #productResult let-result>
    <div class="flex items-center">
        <img class="min-w-10 w-10 min-h-10 h-10 border-2 object-cover" *ngIf="!result.avatar" src="assets/images/products/noimage.png" [alt]="result.name">
        <img class="min-w-10 w-10 min-h-10 h-10 border-2 object-cover" *ngIf="result.avatar" [src]="downloadFile(result.avatar)" [alt]="result.name">
        <div class="flex flex-col leading-normal ml-3 truncate">
            <span [innerHTML]="result.name"></span>
            <span class="itz-text-cool-grey">{{result.sku}}</span>
        </div>
    </div>
</ng-template>

<ng-template #categoryResult let-result>
    <div class="flex items-center">
        <img class="min-w-10 w-10 min-h-10 h-10 border-2 object-cover" *ngIf="!result.avatar" src="assets/images/products/noimage.png" [alt]="result.name">
        <img class="min-w-10 w-10 min-h-10 h-10 border-2 object-cover" *ngIf="result.avatar" [src]="downloadFile(result.avatar)" [alt]="result.name">
        <div class="flex flex-col leading-normal ml-3 truncate">
            <span [innerHTML]="result.name"></span>
        </div>
    </div>
</ng-template>